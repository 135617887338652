<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="pageHeader.title" :icon="pageHeader.icon" :breadcrumbs="breadcrumbs">
      <template v-slot:button v-if="action">
        <v-slide-x-transition>
            <v-btn @click="action = false" height="42" width="147" color="primary" class="rounded-pill mr-6" style="text-transform: none !important">
              Acciones
            </v-btn>
        </v-slide-x-transition>
      </template>
    </BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <TheUsersTable
          :search="search"
          :premium="premium"
          :seccion="seccion"
          :institucion="institucion"
          :page="page"
          :tipo-pago="tipoPago"
          :action="action"
          @closeAction="action=true"
        ></TheUsersTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Users",
  components: {
    TheUsersTable: () =>
      import(
        "@/components/dashboardComponents/TheUsersTable"
      ),
  },
  props: ['search', 'premium', 'seccion', 'institucion', 'page', 'tipoPago',],
  data: () => ({
    pageHeader: {
      title: "Usuarios"
    },
    action: true,
    elementVisible: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/dashboard/index/"
      },
      {
        text: "Usuarios",
        disabled: true,
        href: "/dashboard/users/"
      }
    ]
  }),
  // beforeRouteLeave (to, from, next) {
  //   if (to.name !== 'UserDetail') {
  //     localStorage.removeItem('filters')
  //   }
  //   next()
  // },
  watch: {
    action () {
      if (!this.action) {
        this.breadcrumbs = [
          {
            text: "Home",
            disabled: false,
            href: "/dashboard/index/"
          },
          {
            text: "Usuarios",
            disabled: true,
            href: "/dashboard/users/"
          },
          {
            text: "Editar Usuarios",
            disabled: true,
            href: "/dashboard/users/"
          }
        ]
      } else {
        this.breadcrumbs =[
          {
            text: "Home",
            disabled: false,
            href: "/dashboard/index/"
          },
          {
            text: "Usuarios",
            disabled: true,
            href: "/dashboard/users/"
          }
        ]
      }
    }
  }
};
</script>
